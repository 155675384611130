<template>
  <div class="video_wrap">
    <video id="my-video"
           ref="videoPlayer"
           class="video-js vjs-default-skin vjs-big-play-button vjs-big-play-centered my_video"
           controls
           preload="auto"
           poster="">
      <source :src="video"
              type="video/mp4">
    </video>
  </div>
</template>

<script>
import videojs from "video.js"
export default {
  data () {
    return {
      video: '',
      player: null,
      videoOptions: {
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 是否静音,默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true,
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

    }
  },
  created () {
    console.log("this.$route.query.video", this.$route.queryvideo)
    this.videoOptions.sources[0].src = this.$route.query.video
  },
  mounted () {
    this.video = this.$route.query.video
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady () {
      // console.log('onPlayerReady', this);
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style lang="scss" scoped>
.video_wrap {
  height: 100vh;
  // margin: 10px auto;
  width: 100%;
  background: #000;
}
.my_video {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  max-height: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
}
</style>